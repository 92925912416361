<template>
  <div class="contain" v-title data-title="支付">
    <div>
      <div class="thpay-title">
        <div class="fz16 bold">付款给</div>
        <div class="th-a">
          <div>
            <img width="60px" height="60px" style="border-radius: 50%;" src="@/assets/img/logo2.png"/>
          </div>
          <div style="margin-left: 10px">
            <div class="fz20" style="text-align: center">北京烤鸭</div>
            <div class="fz16" style="text-align: center">和平路店</div>
          </div>
        </div>
      </div>
      <div class="thpay-contain">
        <div class="fz16">付款金额</div>
        <div class="thpay-contain-2" style="width: 356px; height: 44px; display: flex; box-sizing: border-box">
          <div style="font-size: 30px">￥</div>
          <input class="jeinput" :value="price" placeholder="输入金额" type="number" autofocus="true" :disabled="!inputState" @input="inputVal"/>
        </div>
        <div class="btn-bottom" style="margin-top: 6vh">
          <van-button style="background-color: red;color: #fff;border-radius: 20px;" block @click="share">分享立减5元</van-button>
          <van-button style="margin-top: 20px;background-color: #dcdee0;color: red;border-radius: 20px;" block @click="pay">立即支付</van-button>
        </div>
      </div>
    </div>
    <audio ref="music" src="https://best.cailgou.com/Voice11.mp3" type="audio/mp3" @timeupdate="updata()"></audio>
  </div>
</template>

<script>
import {
  getShortUrl8,
  getShortUrl
} from '@/common/api'
import { versions, randomString } from '@/common/util'
var initTime = 0
export default {
  name: 'tPay',
  data () {
    return {
      price: undefined,
      inputState: true,
      time: undefined,
      initTime: 0,
      playState: false,
      wxUrl: undefined,
      chekState: true
    }
  },
  mounted () {
    const ver = versions()
    console.log(ver)
    const r = randomString()
    console.log(r)
    this.getScheme()
  },
  methods: {
    getScheme () {
      getShortUrl().then(res => {
        this.wxUrl = res.resultObject
      })
    },
    getScheme8 () {
      getShortUrl8().then(res => {
        console.log(res)
        if (res.result > -1) {
          location.href = this.wxUrl
          this.chekState = false
        }
      })
    },
    share () {
      this.chekState = true
      this.$refs.music.play()
      location.href = 'https://best.cailgou.com/base/dy/junpdy'
      // const ver = versions()
      // this.$dialog.confirm({
      //   title: '提示',
      //   message: '为了更好的为您服务，请先完善资料'
      // }).then(() => {
      //   clearTimeout(this.time)
      //   if (ver.wxMiniProgram) {
      //     console.log(22)
      //   }
      // }).catch(() => {
      //   this.$toast('用户关闭了弹窗')
      //   console.log('用户关闭了弹窗')
      // })
    },
    pay () {
      location.href = this.wxUrl
    },
    updata () {
      initTime = initTime + 1
      console.log(initTime)
      if (initTime % 7 === 0) {
        if (this.chekState !== false) {
          this.getScheme8()
        }
      }
    },
    inputVal (e) {
      let val = e.detail.value
      val = val.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
      val = val.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
      val = val.replace(/^0+\./g, '0.')
      val = val.replace(/^0+[0-9]+/, '0')
      val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
      this.setData({
        price: val
      })
    }
  }
}
</script>

<style scoped lang="scss">
.contain {
}
.thpay-title {
  padding: 40px 10px 40px 10px;
  background-color: #F3F3F3;
  .th-a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
}
.thpay-contain {
  padding: 20px 5px;
  .thpay-contain-2 {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
  }
  .jeinput {
    width: 300px;
    font-size: 20px;
    border: none;
    background-color: #fff;
    padding: 5px 20px 2px 5px;
  }
}
.btn-bottom .van-button__text {
  font-size: 16px;
}

</style>
